import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { SingleDocResponseData } from "~/api/query_fns/documents";
import { StepReportComponent } from "../components/StepReportComponent";
import UploadArea from "./Documents/UploadArea";
import { useGenerateReportNew } from "~/hooks/useGenerateReportNew";
import { generateQueryExpansionsReportLLMService } from "~/api/query_fns/query-expansion";
import { LoadingComponent } from "~/utils/util-report";
import {
  headingsPlugin,
  listsPlugin,
  quotePlugin,
  thematicBreakPlugin,
  markdownShortcutPlugin,
  MDXEditor,
  tablePlugin,
} from "@mdxeditor/editor";
import "@mdxeditor/editor/style.css";

import { WandIcon, ArrowUpIcon, Loader2Icon } from "lucide-react";
import { cn } from "~/utils";

import { useMutation } from "react-query";
import {
  PromptTemplate,
  postPromptTemplate,
  getPromptTemplates,
  putPromptTemplate,
  destroyPromptTemplate,
  PromptTemplateGroup,
  PromptGroupType,
} from "~/api/query_fns/prompt_template";

import { postVotePromptTemplate } from "~/api/query_fns/vote_prompt_template";

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
  SelectLabel,
} from "~/components/ui/select";

import { Button } from "~/components/ui/button"; // Add this import
import { Switch } from "~/components/ui/switch"; // Add this import
import { Label } from "~/components/ui/label"; // Add this import
import { Input } from "~/components/ui/input"; // Add this import
import toast from "react-hot-toast"; // Add this import

interface ReportDescriptionProps {
  additionalNotes: string;
  setAdditionalNotes: (notes: string) => void;
  isShared: boolean;
  setIsShared: (shared: boolean) => void;
  uploadedDocs: SingleDocResponseData[];
}

export const formatTitle = (input: string): string => {
  // Split the input string by underscores and spaces
  const words = input.split(/[_\s]/);

  // Capitalize the first letter of each word and join them with spaces
  const capitalizedWords = words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");

  return capitalizedWords;
};

export const isEmailDefined = (option: {
  email?: string | null;
}): option is { email: string } => {
  return option.email !== null && option.email !== undefined;
};

export default function UCPolicyComparisonNew() {
  const {
    isLoading,
    handleGenerateReport,
    additionalNotes,
    setAdditionalNotes,
    uploadedDocs,
    handleUploadComplete,
    reportTitle,
    setReportTitle,
  } = useGenerateReportNew("generic_report");

  const [isShared, setIsShared] = useState(false); // Add this line

  if (isLoading) {
    return <LoadingComponent title="GENERATING CUSTOM REPORT" />;
  }

  return (
    <StepReportComponent
      title="Generate Custom Report"
      lastStepName="Generate Custom Report"
      steps={[
        {
          number: 1,
          label: "Report Title",
          description: "Please provide a title for this report",
          component: (
            <ReportTitle
              reportTitle={reportTitle}
              setReportTitle={setReportTitle}
            />
          ),
        },
        {
          number: 2,
          label: "Documents",
          description: "Upload any documents relevant for the report",
          component: (
            <ReportDocuments
              uploadedDocs={uploadedDocs}
              handleUploadComplete={handleUploadComplete}
            />
          ),
        },
        {
          number: 3,
          label: "Description",
          description:
            "Please type in a detailed description of exactly what you want to receive in this report. For best results, please clearly describe the task you would like the report to accomplish, the context or details needed to accomplish that text, and the desired format for the output. For example, 'You will be provided with two PDF documents: an expiring property and casualty insurance policy and its corresponding renewal quote. [Context] Analyze the two documents to identify and compare key policy provisions. [Task] Present the findings in a clear and concise format using bullet points to summarize key changes followed by detailed explanations for each point. [Format].'",
          component: (
            <ReportDescription
              additionalNotes={additionalNotes}
              setAdditionalNotes={setAdditionalNotes}
              isShared={isShared}
              setIsShared={setIsShared}
              uploadedDocs={uploadedDocs}
            />
          ),
        },
      ]}
      onFinalStep={handleGenerateReport}
      onCancelPath="/generic-report"
    />
  );
}

interface ReportTitleProps {
  reportTitle: string;
  setReportTitle: (title: string) => void;
}

export const ReportTitle: React.FC<ReportTitleProps> = ({
  reportTitle,
  setReportTitle,
}) => {
  return (
    <input
      type="text"
      className="w-1/2 border border-gray-300 p-2 "
      placeholder="Enter report title here...E.g. Policy Comparison"
      value={reportTitle}
      onChange={(e) => setReportTitle(e.target.value)}
    />
  );
};

const ReportDescription: React.FC<ReportDescriptionProps> = ({
  additionalNotes,
  setAdditionalNotes,
  isShared,
  setIsShared,
  uploadedDocs,
}) => {
  // Fetch prompt templates
  const {
    data: promptTemplates = [] as PromptTemplateGroup[],
    // isLoading,
    // error,
    refetch,
  } = useQuery({
    queryKey: ["response"],
    queryFn: () => getPromptTemplates(),
    refetchOnWindowFocus: true,
  });

  const [isWandActive, setIsWandActive] = useState(false);
  const [localNotes, setLocalNotes] = useState(additionalNotes);
  const [forceUpdate, setForceUpdate] = useState(0);
  const showWandButton = true;
  const [reportTitle, setReportTitle] = useState("");
  const [selectedOption, setSelectedOption] = useState<PromptTemplate | null>(
    null
  );
  const [hasBeenEdited, setHasBeenEdited] = useState(false);
  const [initialNotes, setInitialNotes] = useState("");
  const [initialTitle, setInitialTitle] = useState("");

  const savePromptTemplate = useMutation(postPromptTemplate);
  const updatePromptTemplate = useMutation(putPromptTemplate);
  const deletePromptTemplate = useMutation(destroyPromptTemplate);
  const votePromptTemplate = useMutation(postVotePromptTemplate);

  const handlePromptChange = (value: string) => {
    const selectedPrompt = promptTemplates
      .flatMap((group: PromptTemplateGroup) => group.options)
      .find((option: PromptTemplate) => option.id === value);

    if (selectedPrompt) {
      setLocalNotes(selectedPrompt.description);
      setAdditionalNotes(selectedPrompt.description);
      setInitialNotes(selectedPrompt.description);
      setInitialTitle(selectedPrompt.name);
      setReportTitle(selectedPrompt.name);
      setHasBeenEdited(false);
      setForceUpdate((prev) => prev + 1);
      setIsShared(selectedPrompt.shared);
      setSelectedOption(selectedPrompt);
      setSelectedGroup(
        promptTemplates.find((group: PromptTemplateGroup) =>
          group.options.includes(selectedPrompt)
        )?.group || null
      );
    }
  };

  useEffect(() => {
    setLocalNotes(additionalNotes);
  }, [additionalNotes]);

  const handleWandClick = async () => {
    console.log("handleWandClick started");
    setIsWandActive(true);

    try {
      console.log("Calling generateQueryExpansionsReportLLMService with:", {
        additionalNotes: localNotes,
      });
      const response = await generateQueryExpansionsReportLLMService({
        user_request: localNotes,
        documents: uploadedDocs.map((doc) => doc.document),
      });
      console.log(
        "Response from generateQueryExpansionsReportLLMService:",
        response
      );

      const newNotes =
        response.report_interpreter_analysis.improved_request ||
        "No expanded query available";
      console.log("Setting additionalNotes to:", newNotes);
      setLocalNotes(newNotes);
      setAdditionalNotes(newNotes);
      setForceUpdate((prev) => prev + 1); // Force a re-render
    } catch (error) {
      console.error("Error generating query expansions:", error);
      console.log("Error details:", JSON.stringify(error, null, 2));
    }

    setIsWandActive(false);
    console.log("handleWandClick completed");
  };

  const [selectedGroup, setSelectedGroup] = useState<string | null>(null);

  const handleNotesChange = (newNotes: string) => {
    console.log("Notes changed:", newNotes);
    setLocalNotes(newNotes);
    setAdditionalNotes(newNotes); // Add this line to update additionalNotes
    setHasBeenEdited(newNotes !== initialNotes || reportTitle !== initialTitle);
  };

  const handleTitleChange = (newTitle: string) => {
    console.log("Title changed:", newTitle);
    setReportTitle(newTitle);
    setHasBeenEdited(localNotes !== initialNotes || newTitle !== initialTitle);
  };

  const toastConfig = {
    position: "top-center" as const,
    style: {
      border: "1px solid #000000",
      padding: "16px",
      color: "#000000",
    },
    iconTheme: {
      primary: "#000000",
      secondary: "#FFFAEE",
    },
  };

  const handleSave = (saveType: "new" | "existing") => {
    setAdditionalNotes(localNotes);

    if (!reportTitle.trim()) {
      toast.error("Report Template Title is required", toastConfig);
      return;
    }

    const mutationConfig = {
      onSuccess: (data: any) => {
        refetch();

        setSelectedOption(data);

        console.log("Mutation onSuccess", data);
      },
      onError: (error: any) => {
        console.error("Error saving template:", error);
        toast.error(`Failed to save the template: ${error}`);
      },
    };

    const savePayload =
      saveType === "new"
        ? {
            name: reportTitle,
            description: localNotes,
            shared: isShared,
            is_owned_by_current_user: true,
            prompt_group: "my_reports" as PromptGroupType,
          }
        : selectedOption
        ? {
            id: selectedOption.id,
            name: reportTitle,
            description: localNotes,
            shared: isShared,
            is_owned_by_current_user: true,
            prompt_group: (isShared
              ? "organization_reports"
              : "my_reports") as PromptGroupType,
          }
        : null;

    if (!savePayload) {
      toast.error("No template selected for update", toastConfig);
      return;
    }

    const saveMutation =
      saveType === "new"
        ? savePromptTemplate.mutate
        : updatePromptTemplate.mutate;

    saveMutation(savePayload, mutationConfig);

    const saveMessage =
      !selectedOption || saveType === "new"
        ? "New report template saved successfully"
        : "Existing report template updated successfully";

    toast.success(saveMessage, toastConfig);
  };

  const handleUpVote = () => {
    if (selectedOption) {
      votePromptTemplate.mutate(
        {
          prompt_template_id: selectedOption.id,
          vote: selectedOption.vote <= 0 ? 1 : -1,
        },
        {
          onSuccess: (data: any) => {
            refetch();
            setSelectedOption((prevTemplate) =>
              prevTemplate
                ? {
                    ...prevTemplate,
                    vote: prevTemplate.vote <= 0 ? 1 : -1,
                  }
                : null
            );

            console.log("Mutation onSuccess", data);
          },
          onError: (error: any) => {
            console.error("Error share template:", error);
            toast.error("Failed to vote the template");
          },
        }
      );

      const message = "Thank you for your vote!";

      toast.success(message, toastConfig);
    }
  };

  const handleShareChange = (newSharedState: boolean) => {
    setIsShared(newSharedState);

    if (selectedOption) {
      updatePromptTemplate.mutate(
        {
          id: selectedOption.id,
          name: reportTitle,
          description: localNotes,
          shared: newSharedState,
          is_owned_by_current_user: true,
          prompt_group: newSharedState ? "organization_reports" : "my_reports",
        },
        {
          onSuccess: (data: any) => {
            refetch();

            setSelectedOption(data);
            console.log("Mutation onSuccess", data);
          },
          onError: (error: any) => {
            console.error("Error share template:", error);
            toast.error("Failed to share the template");
          },
        }
      );
    }

    const message = newSharedState
      ? "Template shared with organization"
      : "Template set to private";

    toast.success(message, toastConfig);
  };

  const handleDelete = () => {
    if (selectedOption) {
      deletePromptTemplate.mutate(
        {
          id: selectedOption.id,
        },
        {
          onSuccess: (data: any) => {
            refetch();

            setSelectedOption(null);

            setLocalNotes("");
            setReportTitle("");
            setIsShared(false);
            setSelectedGroup(null);

            console.log("Mutation onSuccess", data);
          },
          onError: (error: any) => {
            console.error("Error share template:", error);
            toast.error("Failed to share the template");
          },
        }
      );

      const message = "Template sucessfully deleted";

      toast.success(message, toastConfig);
    }
  };

  // Add this useEffect for debugging
  useEffect(() => {
    console.log("State updated:", {
      localNotes,
      reportTitle,
      initialNotes,
      initialTitle,
      hasBeenEdited,
      isButtonDisabled:
        !hasBeenEdited || (!localNotes.trim() && !reportTitle.trim()),
    });
  }, [localNotes, reportTitle, initialNotes, initialTitle, hasBeenEdited]);

  useEffect(() => {
    if (
      hasBeenEdited &&
      selectedOption &&
      !selectedOption.isOwnedByCurrentUser
    ) {
      setReportTitle("");
    }
  }, [hasBeenEdited, selectedOption]);

  return (
    <>
      <div className="mb-2 flex items-start justify-between">
        <div id="dropdown-generic-report" className="mb-4 ml-12 mt-4 flex-grow">
          <Select onValueChange={handlePromptChange}>
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Select A Report Template" />
            </SelectTrigger>
            <SelectContent>
              {promptTemplates.map((group, groupIndex) => (
                <SelectGroup key={groupIndex}>
                  <SelectLabel className="pl-4">
                    {formatTitle(group.group)}
                  </SelectLabel>
                  {group.options.map((option, optionIndex) => (
                    <SelectItem
                      key={`${groupIndex}-${optionIndex}`}
                      value={option.id}
                    >
                      {option.name}
                      {(group.group === "organization_reports" ||
                        group.group === "my_reports") && (
                        <span className="ml-2 text-sm text-gray-500">
                          {(group.group === "organization_reports" ||
                            group.group === "my_reports") &&
                            option.upvotes &&
                            option.upvotes > 0 && (
                              <span className="tag mr-2 rounded-full bg-blue-100 px-2 py-1 text-blue-800">
                                {option.upvotes} Upvotes
                              </span>
                            )}
                          {option.shared && isEmailDefined(option) && (
                            <span className="tag rounded-full bg-green-100 px-2 py-1 text-green-800">
                              {group && group.group === "my_reports"
                                ? "Shared"
                                : `Shared by ${option.email
                                    .split("@")[0]
                                    .toUpperCase()}`}
                            </span>
                          )}
                        </span>
                      )}
                    </SelectItem>
                  ))}
                </SelectGroup>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div id="button-bar" className="flex items-center space-x-2">
          {selectedOption &&
            selectedOption.promptGroup === "organization_reports" && (
              <button
                onClick={handleUpVote}
                className={cn(
                  "inline-flex items-center justify-center text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
                  "h-10 transform rounded-md border border-black px-3 py-2 text-black transition-transform duration-200 ease-in-out hover:scale-105 hover:bg-accent/60"
                )}
              >
                <ArrowUpIcon className="mr-2 h-4 w-4" />
                {selectedOption && selectedOption.vote <= 0
                  ? "Up Vote"
                  : "Down Vote"}
              </button>
            )}
        </div>
      </div>

      <div className="flex items-start space-x-2">
        <div className="flex-shrink-0">
          {showWandButton && !isWandActive && (
            <button
              onClick={handleWandClick}
              className={cn(
                "inline-flex items-center justify-center text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
                "h-10 w-10 p-0 text-black hover:bg-accent/60"
              )}
            >
              <WandIcon className="h-6 w-6" />
            </button>
          )}
          {isWandActive && <Loader2Icon className="h-6 w-6 animate-spin" />}
        </div>

        <div className="flex-grow rounded-lg border border-gray-300">
          <div className="flex">
            <div
              className="flex-grow"
              style={{ overflowY: "auto" }}
              aria-disabled={isWandActive}
            >
              <MDXEditor
                key={forceUpdate}
                onChange={(markdown) => handleNotesChange(markdown)}
                markdown={localNotes}
                className="copilotKitMarkdown min-h-1/2 max-h-1/2"
                plugins={[
                  tablePlugin(),
                  headingsPlugin(),
                  listsPlugin(),
                  quotePlugin(),
                  thematicBreakPlugin(),
                  markdownShortcutPlugin(),
                ]}
                placeholder="Please type in your own prompt or choose one we have pre-written for particular situations which you may edit."
              />
            </div>
          </div>
          {selectedGroup !== "qumis_reports" && selectedOption && (
            <div
              id="template-footer"
              className="mt-4 flex items-center justify-between space-x-4 border-t p-4"
            >
              <div className="flex items-center space-x-2">
                <Input
                  type="text"
                  placeholder="Enter Report Template Title"
                  value={reportTitle}
                  onChange={(e) => handleTitleChange(e.target.value)}
                  className="w-64"
                  maxLength={50}
                />
                <span className="text-sm text-gray-500">
                  {reportTitle.length}/50
                </span>
              </div>

              <div className="flex items-center space-x-2">
                <Label
                  htmlFor="share-template"
                  className="text-sm text-gray-700"
                >
                  {selectedGroup === "qumis_reports"
                    ? "Shared with Organization"
                    : "Share with Organization"}
                </Label>
                <Switch
                  id="share-template"
                  checked={isShared || selectedGroup === "qumis_reports"}
                  onCheckedChange={handleShareChange}
                  disabled={selectedGroup === "qumis_reports"}
                  className="data-[state=checked]:bg-black"
                />
              </div>

              <div className="flex items-center space-x-2">
                {selectedOption && selectedOption.isOwnedByCurrentUser ? (
                  <>
                    <Button
                      onClick={() => handleSave("existing")}
                      variant="outline"
                      size="sm"
                      className="border-black p-2"
                      disabled={!hasBeenEdited || !reportTitle.trim()}
                    >
                      Save Existing Template
                    </Button>
                    <Button
                      onClick={() => handleSave("new")}
                      variant="outline"
                      size="sm"
                      className="border-black p-2"
                      disabled={
                        !hasBeenEdited ||
                        (!localNotes.trim() && !reportTitle.trim())
                      }
                    >
                      Save As New Template
                    </Button>
                    {selectedOption.isOwnedByCurrentUser && (
                      <Button
                        onClick={() => handleDelete()}
                        variant="outline"
                        size="sm"
                        className="border-black"
                      >
                        Delete
                      </Button>
                    )}
                  </>
                ) : (
                  <Button
                    onClick={() => handleSave("new")}
                    variant="outline"
                    size="sm"
                    className="border-black"
                    disabled={
                      !hasBeenEdited ||
                      (!localNotes.trim() && !reportTitle.trim())
                    }
                  >
                    Save As New Template
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

interface ReportDocumentsProps {
  uploadedDocs: SingleDocResponseData[];
  handleUploadComplete: (docResponse: SingleDocResponseData) => void;
}

const ReportDocuments: React.FC<ReportDocumentsProps> = ({
  handleUploadComplete,
}) => {
  return (
    <UploadArea
      db_document_type="attachment"
      onUploadComplete={handleUploadComplete}
    />
  );
};

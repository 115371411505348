import { createDocument } from "~/api";
import { useDropzone } from "react-dropzone";
import { useState } from "react";
import { Progress } from "~/components/ui/progress";
import { Alert } from "~/components/ui/alert";
import { CheckCircledIcon, Cross2Icon } from "@radix-ui/react-icons";
import { useQueryClient } from "react-query";
import { SingleDocResponseData } from "~/api/query_fns/documents";
import { DbDocumentType } from "~/api/query_fns/documents";

const UploadArea = ({
  db_document_type,
  onUploadComplete,
  description = "PDF format only",
}: {
  db_document_type: DbDocumentType;
  onUploadComplete?: (info: SingleDocResponseData) => void;
  description?: string;
}) => {
  const [fileCount, setFileCount] = useState<number>(0);
  const [completedFiles, setCompletedFiles] = useState<
    { filename: string; status: "accepted" | "rejected"; message?: string }[]
  >([]);
  const queryClient = useQueryClient();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "application/pdf": [".pdf", ".PDF"],
      "application/vnd.ms-excel": [".xls", ".XLS"],
      "image/jpeg": [".jpeg", ".jpg", ".JPEG", ".JPG"],
      "image/png": [".png", ".PNG"],
      "image/tiff": [".tiff", ".tif", ".TIFF", ".TIF"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
        ".XLSX",
      ],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx", ".DOCX"],
    },
    onDrop: (acceptedFiles, fileRejections) => {
      setFileCount((old) => old + acceptedFiles.length + fileRejections.length);

      acceptedFiles.forEach((file) => {
        const reader = new FileReader();

        reader.onload = async () => {
          try {
            const docResponse: SingleDocResponseData = await createDocument({
              filename: file.name,
              document_type: db_document_type,
            });

            await fetch(docResponse.presignedUrl, {
              method: "PUT",
              headers: {
                "Content-Type": file.type,
              },
              body: file,
            });
            queryClient.invalidateQueries("referenceDocuments");

            setCompletedFiles((old) => [
              { filename: file.name, status: "accepted" },
              ...old,
            ]);

            if (onUploadComplete) {
              onUploadComplete(docResponse);
            }
          } catch (error) {
            setCompletedFiles((old) => [
              {
                filename: file.name,
                status: "rejected",
                message: (error as Error).message || "API request error",
              },
              ...old,
            ]);
          }
        };

        reader.readAsArrayBuffer(file);
      });

      fileRejections.map(({ file, errors }) => {
        setCompletedFiles((old) => [
          {
            filename: file.name,
            status: "rejected",
            message: errors.map((e) => e.message).join(", "),
          },
          ...old,
        ]);
      });
    },
  });

  const isUploading = fileCount > 0 && fileCount > completedFiles.length;

  return (
    <div className="rounded-lg border border-gray-300">
      <div {...getRootProps()}>
        <div
          className={`border-dashed p-8 text-center ${
            isDragActive
              ? "border-palette-brand-green/80 bg-palette-brand-green/5"
              : "border-gray-200"
          }`}
        >
          <input {...getInputProps()} />
          {isUploading ? (
            <>
              <div>Uploading...</div>
              <Progress
                className="mt-2"
                value={100 * (completedFiles.length / fileCount)}
              />
            </>
          ) : (
            <>
              <span className="cursor-pointer text-primary underline underline-offset-4 hover:text-primary/90">
                Click here
              </span>{" "}
              or drag a file to upload.
              <div className="mt-2 text-xs text-gray-400">{description}</div>
            </>
          )}
        </div>
      </div>
      {completedFiles.length > 0 && (
        <ul className="mt-6 flex flex-col gap-3">
          {completedFiles.map((file, index) => (
            <li className="m-0 list-none" key={`${index}_${file.filename}`}>
              <Alert
                variant={file.status === "accepted" ? "success" : "destructive"}
              >
                {file.status === "accepted" ? (
                  <CheckCircledIcon className="mt-0.5 h-5 w-5" />
                ) : (
                  <Cross2Icon className="mt-0.5 h-5 w-5" />
                )}
                <p className="font-semibold">{file.filename}</p>
                <p className="text-sm">{file.message}</p>
              </Alert>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default UploadArea;
